import React, { Component } from 'react';
import Swal from 'sweetalert2';
import moment from 'moment';
import Loading from '../assets/img/wait.gif';
import instance from '../api';
import RpmTableHeader from '../components/RpmTableHeader'

const errorStyle = {
  fontSize: '10px',
  color: 'red',
  display: 'block'
};


let headers = [
  // {
  //   name: 'Business Type',
  //   field: 'businessType',
  //   sortable: false,
  //   align: 'left'
  // },
  {
    name: 'GroupID',
    field: 'benefitGroupID',
    sortable: false,
    align: 'left'
  },
  {
    name: 'Group Name',
    field: 'benefitPackageName',
    sortable: false,
    align: 'left'
  },
  {
    name: 'Benefit Package Number',
    field: 'benefitPackageNumber',
    sortable: false,
    align: 'left'
  },
  {
    name: 'CMS Contract Number',
    field: 'CMSContractNumber',
    sortable: false,
    align: 'left'
  },
  {
    name: 'PBP Number',
    field: 'PBPNumber',
    sortable: false,
    align: 'left'
  },
  {
    name: 'Group StartDate',
    field: 'groupStartDate',
    sortable: false,
    align: 'left'
  }, {
    name: 'Group EndDate',
    field: 'groupEndDate',
    sortable: false,
    align: 'left'
  }, {
    name: 'Benefit StartDate',
    field: 'benefitStartDate',
    sortable: false,
    align: 'left'
  },
  {
    name: 'Benefit EndDate',
    field: 'benefitEndDate',
    sortable: false,
    align: 'left'
  },
  {
    name: 'CoPay Amount',
    field: 'copayAmount',
    sortable: false,
    align: 'left'
  }
];

const benefitFields = [
  "benefitGroupID",
  "benefitPackageName",
  "businessType",
  "benefitPackageNumber",
  "CMSContractNumber",
  "PBPNumber",
  "groupStartDate",
  "groupEndDate",
  "benefitStartDate",
  "benefitEndDate",
  "copayAmount"
];
class Benefits extends Component {
  constructor(props) {
    super();
    this.state = {
      tab: 'MA',
      profile: JSON.parse(localStorage.getItem('profile')),
      searchKey: '',
      benefits: [],
      payload: {
        CMSContractNumber: null,
        PBPNumber: null,
        benefitEndDate: null,
        benefitGroupID: null,
        benefitPackageName: null,
        benefitPackageNumber: null,
        benefitStartDate: null,
        businessType: null,
        copayAmount: null,
        groupEndDate: null,
        groupStartDate: null,
      },
      error: {},
      loading: true,
      visibleForm: false
    }
    this.createBenefit = this.createBenefit.bind(this);
    this.updateBenefit = this.updateBenefit.bind(this);
    this.editBenefit = this.editBenefit.bind(this);
    this.generateTable = this.generateTable.bind(this);
    this.generateForm = this.generateForm.bind(this);
    this.resetPayload = this.resetPayload.bind(this);
    this.getErrorMessage = this.getErrorMessage.bind(this);
    this.validateData = this.validateData.bind(this);
    this.editActions = this.editActions.bind(this);
  }

  createBenefit(e) {
    e.preventDefault();
    if (this.validateData()) {
      instance.post("/benefit/benefit", this.state.payload).then(res => {
        this.setState({
          visibleForm: false
        });
        Swal.fire(
          'Success',
          'Benefit created successfully',
          'success'
        );
        this.getDataList();
      }, err => {
        if (err.response.status === 400) {
          this.setState({
            error: err.response.data
          });
        }
      });
    }
  }

  validateData() {
    let proceed = true;
    let error = {};
    let IgnoreFields = ["benefitPackageNumber", "copayAmount"];
    if (this.state.payload.businessType === "Commercial") {
      IgnoreFields = ["benefitPackageNumber", "CMSContractNumber", "PBPNumber", "copayAmount"];
    }
    benefitFields.forEach(field => {
      if (IgnoreFields.indexOf(field) === -1 && !this.state.payload[field] || this.state.payload[field] === "") {
        error[field] = `${field} is required`;
        proceed = false;
      }
    });
    if (this.state.payload.copayAmount === null || this.state.payload.copayAmount < 0) {
      error.copayAmount = "Invalid copayAmount";
      proceed = false;
    }
    if (proceed) {
      const groupStartDate = moment(this.state.payload.groupStartDate);
      const groupEndDate = moment(this.state.payload.groupEndDate);
      const benefitStartDate = moment(this.state.payload.benefitStartDate);
      const benefitEndDate = moment(this.state.payload.benefitEndDate);
      if (groupStartDate.diff(benefitStartDate, 'days')>0 || groupEndDate.diff(benefitStartDate, 'days')<0) {
        error.benefitStartDate = "Benefit StartDate is not in range";
        proceed = false;
      }
      if (benefitStartDate.diff(benefitEndDate, 'days')>0 || groupStartDate.diff(benefitEndDate, 'days')>0 || groupEndDate.diff(benefitEndDate, 'days')<0) {
        error.benefitEndDate = "Benefit EndDate is not in range";
        proceed = false;
      }
    }
    this.setState({
      error: error
    });
    return proceed;
  }

  updateBenefit(e) {
    e.preventDefault();
    if (this.validateData()) {
      instance.put(`/benefit/benefit/${this.state.payload.id}`, this.state.payload).then(res => {
        this.setState({
          visibleForm: false
        });
        Swal.fire(
          'Success',
          'Benefit updated successfully',
          'success'
        )
        this.getDataList();
      }, err => {
        if (err.response.status === 400) {
          this.setState({
            error: err.response.data
          });
        }
      });
    }
  }

  deleteBenefit(id) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete the benefit',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        instance.delete(`/benefit/benefit/${id}`).then(res => {
          Swal.fire(
            'Success',
            'Benefit deleted successfully',
            'success'
          )
          this.getDataList();
        }, err => { });
      }
    })
  }

  editBenefit(item) {
    this.setState(prevState => ({
      payload: {
        ...prevState.payload,
        ...item
      }
    }));
  }

  getDataList(businessType=null) {
    this.setState({
      loading: true
    });
    instance.get("/benefit/benefit", {
      params: {
        search: this.state.searchKey,
        businessType: businessType || this.state.tab
      }
    }).then(res => {
      this.setState({
        benefits: res.data,
        loading: false
      });
    }, err => {
      console.log('Unable to get benefits....');
    });
  }

  resetPayload() {
    this.setState({
      payload: {
        businessType: this.state.tab,
        benefitGroupID: null,
        benefitPackageNumber: null,
        benefitPackageName: null,
        groupStartDate: null,
        groupEndDate: null,
        benefitStartDate: null,
        benefitEndDate: null,
        CMSContractNumber: null,
        PBPNumber: null,
        copayAmount: null
      },
      error: {},
      visibleForm: true
    });
  }

  componentDidMount() {
    if (this.state.profile && this.state.profile.role !== 'view') {
      headers.push({
        name: 'Actions',
        field: 'Actions',
        sortable: false,
        align: 'left'
      });
    }
    this.getDataList();
  }

  editActions(item) {
    if (this.state.profile && this.state.profile.role !== 'view') {
      return <td>
        <i className="fa fa-pencil" style={{ marginRight: '10px', cursor: 'pointer' }} onClick={() => {
          item.groupStartDate = moment(item.groupStartDate).utc().format('YYYY-MM-DD');
          item.groupEndDate = moment(item.groupEndDate).utc().format('YYYY-MM-DD');
          item.benefitStartDate = moment(item.benefitStartDate).utc().format('YYYY-MM-DD');
          item.benefitEndDate = moment(item.benefitEndDate).utc().format('YYYY-MM-DD');
          this.setState(prevState => ({
            payload: {
              ...prevState.payload,
              ...item
            },
            error: {},
            visibleForm: true
          }))
        }}></i>
        <i className="fa fa-trash" style={{ cursor: 'pointer' }} onClick={() => {
          this.deleteBenefit(item.id)
        }}></i>
      </td>;
    }
  }

  generateTable(state) {
    if (state.loading) {
      return <tbody>
        <tr>
          <td colSpan="12" className="text-center">
            <img src={Loading} style={{ width: '250px' }} />
          </td>
        </tr>
      </tbody>
    } else if (this.state.benefits.length === 0) {
      return <tbody>
        <tr>
          <td colSpan="12" className="text-center">
            <p className="text-center">No entries found</p>
          </td>
        </tr>
      </tbody>
    } else {
      return <tbody>
        {this.state.benefits.map(item => (
          <tr key={item.id}>
            {/* <td>{item.businessType}</td> */}
            <td>{item.benefitGroupID}</td>
            <td>{item.benefitPackageName}</td>
            <td>{item.benefitPackageNumber}</td>
            <td>{item.CMSContractNumber}</td>
            <td>{item.PBPNumber}</td>
            <td>{moment(item.groupStartDate).utc().format('MMM,DD,YYYY')}</td>
            <td>{moment(item.groupEndDate).utc().format('MMM,DD,YYYY')}</td>
            <td>{moment(item.benefitStartDate).utc().format('MMM,DD,YYYY')}</td>
            <td>{moment(item.benefitEndDate).utc().format('MMM,DD,YYYY')}</td>
            <td>${item.copayAmount}</td>
            {this.editActions(item)}
          </tr>
        ))}
      </tbody>
    }
  }

  getErrorMessage(field) {
    return this.state.error[field] ? <span style={errorStyle}>{this.state.error[field]}</span> : '';
  }

  generateForm() {
    let saveBtn = <div className="careButton" disabled="" id="signIn" value="Go" onClick={this.createBenefit}>Create</div>
    let header = <h6>Create Benefit</h6>;
    if (this.state.payload.id) {
      header = <h6>Edit Benefit</h6>;
      saveBtn = <div className="careButton" disabled="" id="signIn" value="Go" onClick={this.updateBenefit}>Update</div>
    }
    if (this.state.visibleForm) {
      return <div className="customContainer" style={{ marginBottom: '20px' }}>
        {header}
        <div className="row">
          <div className="col-3">
            <div className="form-group">
              <label>Business Type</label><br />
              <select className="form-control" value={this.state.payload.businessType} onChange={(event) => {
                this.setState(prevState => ({
                  payload: {
                    ...prevState.payload,
                    businessType: event.target.value
                  }
                }));
              }}>
                <option disabled value="null" selected>Select</option>
                <option value="MA">MA</option>
                <option value="Commercial">Commercial</option>
              </select>
              {this.getErrorMessage('businessType')}
            </div>
          </div>
          <div className="col-3">
            <div className="form-group">
              <label>GroupID</label><br />
              <input type="text" className="form-control" value={this.state.payload.benefitGroupID} onChange={(event) => {
                this.setState(prevState => ({
                  payload: {
                    ...prevState.payload,
                    benefitGroupID: event.target.value
                  }
                }));
              }} placeholder="Enter GroupID" />
              {this.getErrorMessage('benefitGroupID')}
            </div>
          </div>
          <div className="col-3">
            <div className="form-group">
              <label>Group Name</label><br />
              <input type="text" className="form-control" value={this.state.payload.benefitPackageName} onChange={(event) => {
                this.setState(prevState => ({
                  payload: {
                    ...prevState.payload,
                    benefitPackageName: event.target.value
                  }
                }));
              }} placeholder="Enter Group Name" />
              {this.getErrorMessage('benefitPackageName')}
            </div>
          </div>
          <div className="col-3">
            <div className="form-group">
              <label>Benefit Package Number</label><br />
              <input type="text" className="form-control" value={this.state.payload.benefitPackageNumber} onChange={(event) => {
                this.setState(prevState => ({
                  payload: {
                    ...prevState.payload,
                    benefitPackageNumber: event.target.value
                  }
                }));
              }} placeholder="Enter Benefit Package Number" />
              {this.getErrorMessage('benefitPackageNumber')}
            </div>
          </div>
          <div className="col-3">
            <div className="form-group">
              <label>CMS Contract Number</label><br />
              <input type="text" className="form-control" value={this.state.payload.CMSContractNumber} onChange={(event) => {
                this.setState(prevState => ({
                  payload: {
                    ...prevState.payload,
                    CMSContractNumber: event.target.value
                  }
                }));
              }} placeholder="Enter CMS Contract Number" />
              {this.getErrorMessage('CMSContractNumber')}
            </div>
          </div>
          <div className="col-3">
            <div className="form-group">
              <label>PBP Number</label><br />
              <input type="text" className="form-control" maxlength="3" value={this.state.payload.PBPNumber} onChange={(event) => {
                this.setState(prevState => ({
                  payload: {
                    ...prevState.payload,
                    PBPNumber: event.target.value
                  }
                }));
              }} placeholder="Enter PBP Number" />
              {this.getErrorMessage('PBPNumber')}
            </div>
          </div>
          <div className="col-3">
            <div className="form-group">
              <label>Group StartDate</label><br />
              <input type="date" className="form-control" value={this.state.payload.groupStartDate} onChange={(event) => {
                this.setState(prevState => ({
                  payload: {
                    ...prevState.payload,
                    groupStartDate: event.target.value
                  }
                }));
              }} placeholder="Enter Group StartDate" />
              {this.getErrorMessage('groupStartDate')}
            </div>
          </div>
          <div className="col-3">
            <div className="form-group">
              <label>Group EndDate</label><br />
              <input type="date" className="form-control" value={this.state.payload.groupEndDate} onChange={(event) => {
                this.setState(prevState => ({
                  payload: {
                    ...prevState.payload,
                    groupEndDate: event.target.value
                  }
                }));
              }} placeholder="Enter Group EndDate" />
              {this.getErrorMessage('groupEndDate')}
            </div>
          </div>
          <div className="col-3">
            <div className="form-group">
              <label>Benefit StartDate</label><br />
              <input type="date" className="form-control" value={this.state.payload.benefitStartDate} onChange={(event) => {
                this.setState(prevState => ({
                  payload: {
                    ...prevState.payload,
                    benefitStartDate: event.target.value
                  }
                }));
              }} placeholder="Enter Benefit StartDate" />
              {this.getErrorMessage('benefitStartDate')}
            </div>
          </div>
          <div className="col-3">
            <div className="form-group">
              <label>Benefit EndDate</label><br />
              <input type="date" className="form-control" value={this.state.payload.benefitEndDate} onChange={(event) => {
                this.setState(prevState => ({
                  payload: {
                    ...prevState.payload,
                    benefitEndDate: event.target.value
                  }
                }));
              }} placeholder="Enter Benefit EndDate" />
              {this.getErrorMessage('benefitEndDate')}
            </div>
          </div>
          <div className="col-3">
            <div className="form-group">
              <label>Copay Amount</label><br />
              <input type="number" className="form-control" value={this.state.payload.copayAmount} onChange={(event) => {
                this.setState(prevState => ({
                  payload: {
                    ...prevState.payload,
                    copayAmount: event.target.value
                  }
                }));
              }} placeholder="Enter Copay Amount" />
              {this.getErrorMessage('copayAmount')}
            </div>
          </div>
          <div className="col-12 text-left">
            <div className="signInContainer-buttons" style={{ justifyContent: 'left' }}>
              {saveBtn}
              <div className="careButton" disabled="" id="signIn" value="Go" onClick={() => {
                this.setState({
                  visibleForm: false
                })
              }}>Cancel</div>
            </div>
          </div>
        </div>
      </div>
    } else {
      return;
    }
  }

  render() {
    let actions;
    if (this.state.profile && this.state.profile.role !== "view") {
      actions = <div>
        <label>&nbsp;</label><br />
        <button className="btn btn-outline-primary btn-sm f-right" onClick={this.resetPayload}>+ Add</button>
        <button className="btn btn-light btn-sm f-right" style={{ marginRight: '10px' }} onClick={() => {
          this.getDataList();
        }}><span className="fa fa-refresh"></span></button>
      </div>;
    }
    return (
      <div className="row justify-content-center">
        <div className="col-12">
          {this.generateForm()}
        </div>
        <div className="col-12">
          <div className="customContainer" style={{ marginBottom: '20px', maxWidth: '100%', overflowX: 'auto' }}>
            <h5 style={{ marginBottom: '20px' }}>Benefits - {this.state.tab==='MA'?'Medicare Advantage':this.state.tab}</h5>
            <div className="row justify-content-start" style={{ margin: '10px 0px' }}>
              <div className="col-2" style={{ paddingLeft: '0px' }}>
                <div className="form-group" style={{ marginBottom: '5px' }}>
                  <label>Business Type</label>
                  <select className="form-control form-control-sm" style={{ width: '100%' }} value={this.state.tab} onChange={(event) => {
                    this.setState(prevState => ({
                      tab: event.target.value
                    }));
                    this.getDataList(event.target.value);
                  }}>
                    <option value="MA">MA</option>
                    <option value="Commercial">Commercial</option>
                  </select>
                </div>
              </div>
              <div className="col" style={{ maxWidth: '14%', paddingLeft: 0 }}>
                <label>&nbsp;</label>
                <input type="search" style={{ width: '100%' }} className="form-control form-control-sm" value={this.state.searchKey} onChange={(event) => {
                  this.setState({
                    searchKey: event.target.value
                  })
                }} onKeyDown={(event) => {
                  if (event.keyCode === 13) {
                    event.preventDefault();
                    this.getDataList();
                  }
                }} placeholder="Search" />
              </div>
              <div className="col text-left" style={{ paddingLeft: 0 }}>
                <label>&nbsp;</label><br />
                <button className="btn btn-outline-success btn-sm" onClick={() => {
                  this.getDataList();
                }}><span className="fa fa-search"></span> Search</button>
              </div>
              <div className="col" style={{ padding: '0px' }}>
                {actions}
              </div>
            </div>
            <table className="table">
              <RpmTableHeader headers={headers}></RpmTableHeader>
              {this.generateTable(this.state)}
            </table>
          </div>
        </div>
      </div>
    );
  }
};

export default Benefits;